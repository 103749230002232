import React, { useEffect, useState } from "react";
import story from "../stories/WinterSolstice.json";

const WinterSolstice = () => {
  const [current, setCurrent] = useState("opening");
  const [name, setName] = useState("");
  const [petType, setPetType] = useState("");
  const [formData, setFormData] = useState(false);

  useEffect(() => {
    if (!name || !petType) {
      setFormData(true);
    }
  }, [name, petType]);

  const getStoryText = () => {
    return story[current].text
      .replace("{name}", name)
      .replace("{pet_type}", petType);
  };

  const getOptionText = (text) => {
    return text.replace("{name}", name).replace("{pet_type}", petType);
  };

  return (
    <div className="container mx-auto py-20">
      {formData ? (
        <div className="flex-col">
          <h1 className="text-3xl font-bold mb-4">
            Input some data here or wtv
          </h1>
          <label>Name (eg. Pelle, Iver)</label>
          <br></br>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="bg-blue-100 px-4 py-2"
          />
          <br></br>
          <label>Pet type (eg. cat, dog)</label>
          <br></br>
          <input
            type="text"
            value={petType}
            onChange={(e) => setPetType(e.target.value)}
            className="bg-blue-100 px-4 py-2"
          />
          <p
            onClick={() => setFormData(false)}
            className="px-2 py-4 bg-blue-200 rounded-xl cursor-pointer hover:bg-blue-300"
          >
            Start
          </p>
        </div>
      ) : (
        <>
          <h1 className="text-3xl font-bold mb-4">{story.title}</h1>
          <p>{getStoryText()}</p>

          {story[current]?.ending ? (
            <div>
              <p>{story[current]?.ending}</p>
              {story[current]?.stats && (
                <ul>
                  <li>
                    Detective Points: {story[current].stats.detective_points}
                  </li>
                  <li>
                    Christmas Spirit: {story[current].stats.christmas_spirit}
                  </li>
                  <li>Family Trust: {story[current].stats.family_trust}</li>
                </ul>
              )}
              <p
                onClick={() => setCurrent("opening")}
                className="px-2 py-4 bg-blue-200 rounded-xl cursor-pointer hover:bg-blue-300"
              >
                Restart
              </p>
            </div>
          ) : (
            <div className="flex space-x-4 mt-4">
              {story[current]?.choices?.map((choice) => (
                <p
                  key={choice.option}
                  onClick={() => setCurrent(choice.next)}
                  className="px-2 py-4 bg-blue-200 rounded-xl cursor-pointer hover:bg-blue-300"
                >
                  {getOptionText(choice.text)}
                </p>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WinterSolstice;
