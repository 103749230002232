import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Halloween from "./components/Halloween";
import Home from "./components/Home";
import Christmas from "./components/Christmas";
import WinterSolstice from "./components/WinterSolstice";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/halloween" element={<Halloween />} />
        <Route path="/missingChristmasStar" element={<Christmas />} />
        <Route path="/winterSolstice" element={<WinterSolstice />} />
      </Routes>
    </Router>
  );
};

export default App;
